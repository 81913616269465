<template>
    <div class="card" style="max-width: 100%;">
        <div v-if="attachmentsModeLocal == 'edit'" class="flex justify-content-start">
            <Button label="Upload" icon="pi pi-upload" @click="openFileInput" style="background-color: rgb(25, 90, 180);" />
            <input ref="fileInput" multiple type="file" style="display: none;" @change="myUploader($event.target.files)">
        </div>
        <div v-if="attachmentsLocal.length > 0" class="flex mt-4">
            <div style="flex: 1; font-weight: 700; padding: 5px;">
                {{ this.$langGetValue('file') }}
            </div>
            <div style="flex: 1; font-weight: 700; padding: 5px;">
                {{ this.$langGetValue('searchOptionDate') }}
            </div>
            <div style="flex: 1; font-weight: 700; padding: 5px;">
                {{ this.$langGetValue('user') }}
            </div>
            <div style="flex: 0.5; padding: 5px;">
            </div>
        </div>
        <hr>

        <div v-if="attachmentsLocal.length > 0">
            <div class="flex justify-content-start" v-for="(column, index) in attachmentsLocal" :key="index" style="padding: 5px;">
                <div class="flex align-items-center" style="flex: 1; overflow: hidden; padding: 5px;">
                    {{ column.name }}
                </div>
                <div class="flex align-items-center" style="flex: 1; overflow: hidden; padding: 5px;">
                    {{ getDate(column.createdTimestamp) }}
                </div>
                <div class="flex align-items-center" style="flex: 1; overflow: hidden; padding: 5px;">
                    {{ getName(column.registeredBy) }}
                </div>
                <div style="flex: 0.5; padding: 5px;">
                    <div v-if="attachmentsModeLocal == 'edit'" class="text-center">
                        <Button title="Baixar" icon="pi pi-download" style="margin: 2px; background-color: rgb(25, 90, 180);" class="p-button-rounded"
                            @click="downloadAttachment(column)" />
                        <Button title="Excluir" icon="pi pi-times" style="margin: 2px; background-color: rgb(25, 90, 180);" class="p-button-rounded"
                            @click="deleteAttachment(column.id)" />
                    </div>
                    <div v-else class="text-center">
                        <Button title="Baixar" icon="pi pi-download" class="p-button-rounded" style="background-color: rgb(25, 90, 180);"
                            @click="downloadAttachment(column)" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="drop-area" @dragenter.prevent="highlight" @dragover.prevent="highlight"
            @dragleave.prevent="unhighlight" @drop.prevent="handleDrop" @click="openFileInput" style="cursor: pointer;">
            <div
                style="margin: 30px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <img src="images/upload_storm.svg" style="width: 100px;" />
                <div class="body2 mt-2" style="width: 30%; display: flex; justify-content: center; text-align: center;">
                    {{ this.$langGetValue('attachmentsEmpty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Util from '../service/Util';
import { ref } from 'vue';
import AttachmentService from '../service/AttachmentService';
import UserService from '../service/UserService';

export default {
    props: {
        attachments: Array,
        attachmentsMode: String,
    },
    setup(props) {
        const attachmentsLocal = ref(props.attachments);
        const attachmentsModeLocal = ref(props.attachmentsMode);
        return {
            attachmentsLocal,
            attachmentsModeLocal,
        };
    },
    data() {
        return {
            tempArray: [],
        };
    },
    watch: {

    },
    mounted() {

    },
    unmounted() {
    },
    methods: {
        // ---------------------------------- Dropzone -----------------------------
        highlight(event) {
            event.preventDefault();
            event.stopPropagation();
            event.target.classList.add('dragover');
        },
        unhighlight(event) {
            event.preventDefault();
            event.stopPropagation();
            event.target.classList.remove('dragover');
        },
        // ---------------------------------- Util-----------------------------
        getDate(date) {
            return Util.formatDate(date, true)
        },
        getName(mail) {
            return Util.emailToName(mail)
        },
        // ---------------------------------- Upload -----------------------------
        openFileInput() {
            this.$refs.fileInput.click();
        },
        handleDrop(event) {
            const files = event.dataTransfer.files;
            this.myUploader(files)
        },
        myUploader(files) {
            this.tempArray = []

            if (files.length === 0) {
                return;
            }

            const maxSizeInBytes = 15 * 1024 * 1024; // 15 MB
            const errorMessage = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.size > maxSizeInBytes) {
                    errorMessage.push(`${file.name} excede o tamanho máximo permitido de 15MB.`);
                }
            }

            if (errorMessage.length > 0) {
                this.$toast.add({
                    severity: "error",
                    summary: "Erro ao subir arquivo",
                    detail: errorMessage[0],
                    life: 1000,
                });
                return
            }

            Util.loading = true;
            Array.from(files).forEach((file) => {
                if (!this.attachmentsLocal.some(obj => obj.name === file.name)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = async () => {
                        await this.uploadOneFile(file, files.length);
                    };

                    reader.onerror = function (error) {
                        console.error("Error: ", error);
                    };
                }
            });
        },
        async uploadOneFile(file, size) {
            try {
                const base64String = await this.readFileAsBase64(file);

                AttachmentService.uploadAttachment(file.name, base64String).then((resp) => {
                    this.tempArray.push({
                        "id": resp.data,
                        "name": file.name,
                        "registeredBy": UserService.user,
                        "createdTimestamp": new Date().toISOString()
                    });

                    if (this.tempArray.length == size) {
                        Util.loading = false;

                        this.attachmentsLocal = this.attachmentsLocal.concat(this.tempArray)
                        this.returnAttachmentsIDs()
                    }

                })

            } catch (error) {
                console.error("Error uploading file:", error);
            }
        },
        async readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function () {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };

                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },
        // ------------------------------- Download ---------------------------
        downloadAttachment(event) {
            AttachmentService.getAttachment(event.id).then((resp) => {
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(resp);
                a.download = event.name;
                a.click();
            })
        },
        deleteAttachment(event) {
            this.attachmentsLocal = this.attachmentsLocal.filter(objeto => objeto.id !== event);
            this.returnAttachmentsIDs()
        },
        // -------------------------------- Return ----------------------------------
        returnAttachmentsIDs() {
            this.$emit('returned-ids', this.attachmentsLocal);
        }
    },
}

</script>

<style></style>