import BackendService from './BackendService';
import UserService from './UserService';

export default class AttachmentService {

    static listAttachment(service, serviceID) {
        return BackendService.get("/attachment/list/" + service + "/" + serviceID).then(resp => {
            return resp
        })
    }

    static uploadAttachment(filename, content) {
        var payload = {
            name: filename,
            forceCreationDev: true,
            bytes: content,
            registeredBy: UserService.user
        }
        return BackendService.post("/attachment/", payload).then(resp => {
            return resp
        })
    }

    static getAttachment(id) {
        return BackendService.get("/attachment/" + id, true).then(resp => {
            return resp
        })
    }

}