<template>
    
    <ul class="list-none p-0 m-0">
        <li v-for="(value, key) in keyValueLocal" :key="key" class="pb-3 border-bottom-1 surface-border">
            <div class="font-medium text-900 mb-2">{{ key }}</div>
            <div class="line-height-3 text-600" style="max-width: 30rem;">{{ value }}</div>
        </li>
    </ul>
</template>

<script>

import { ref } from 'vue';

export default {
    props: {
        keyValue: Object,
    },
    setup(props) {
        const keyValueLocal = ref(props.keyValue);

        return {
            keyValueLocal,
        };
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    unmounted() {
    },
    methods: {
        getType(value) {
            return Array.isArray(value) ? 'array' : typeof value;
        }
    },
};

</script>