
import BackendService from './BackendService';
import ContentService from './ContentService';

export default class IssueService {

    static getIssue(IssueID) {
        var local = false
        var port = '5000'
        var url = "/issue/"
        // if (window.location.href.includes("localhost")) {
        //     local = true
        //     url = "/"
        // }
        return BackendService.get(url + IssueID + "?lang=" + ContentService.currentLang, false, local, port).then(resp => {
            console.log(IssueID)
            return resp
        })
    }

    static issueList(payload) {
        return BackendService.post("/issue/list", payload).then(resp => {
            return resp
        })
    }

    static getResponsibleOfControlArea(user) {
        return BackendService.get("/issue/fupresponsibles/" + user).then(resp => {
            return resp
        })
    }

    static getAPbyID(ApID) {
        var local = false
        var port = '5000'
        var url = "/action-plan/"
        //if (window.location.href.includes("localhost")) {
        //    local = true
        //    url = "/"
        //}
        return BackendService.get(url + ApID, false, local, port).then(resp => {
            console.log(resp)
            return resp
        })
    }

    static createIssue(payload) {
        var local = false
        var port = '5000'
        var url = "/issue/"
        // if (window.location.href.includes("localhost")) {
        //     local = true
        //     url = "/"

        // }
        console.log(payload.title)
        console.log(payload)
        return BackendService.post(url, payload, false, local, port).then(resp => {
            return resp
        })
    }

    static updateIssue(IssueID, payload) {
        var local = false
        var port = '5000'
        var url = "/issue/"
        // if (window.location.href.includes("localhost")) {
        //     local = true
        //     url = "/"

        // }
        console.log("Updating:")
        console.log(IssueID)
        console.log(payload.title)
        console.log(payload)
        return BackendService.put(url + IssueID, payload, false, local, port).then(resp => {
            return resp
        })
    }
}


