
import BackendService from './BackendService';

export default class ActionPlanService {

    static getAP(ApID) {
        var local = false
        var port = '5000'
        var url = "/action-plan/"
        //if (window.location.href.includes("localhost")) {
        //    local = true
        //    url = "/"
        //}
        return BackendService.get(url + ApID, false, local, port).then(resp => {
            console.log(resp)
            return resp
        })
    }

    static apList(payload) {
        return BackendService.post("/action-plan/list", payload).then(resp => {
            return resp
        })
    }

}


