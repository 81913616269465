<template>
  <Toast />
  <div>
    <div style="display: flex; flex-direction: column; justify-content: start">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 16px;
          margin-bottom: 32px;
        "
      >
        <button
          class="primaryButton"
          style="
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 8px;
          "
          v-if="!this.blockComponent"
          @click="handleNewAP"
        >
          <i class="pi pi-plus"></i>
          <div class="body2" style="font-weight: 600; color: white">
            {{this.$langGetValue('newActionPlan')}}  
          </div>
        </button>
        <button
          class="secondaryButton"
          style="
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 8px;
          "
          v-if="!this.blockComponent"
          @click="handleLinkAP"
        >
          <i class="pi pi-link"></i>
          <div class="body2" style="font-weight: 600">
            {{this.$langGetValue('linkOldActionPlan')}}  
          </div>
        </button>
      </div>
      <div v-if="actionsPlansQuery?.length > 0" style="width: 100%">
        <DataTable
          paginator
          :rows="5"
          sortField="status"
          :sortOrder="1"
          :value="actionsPlansQuery"
          columnResizeMode="fit"
          stripedRows
          tableStyle="width: 100%"
          :disabled="this.blockComponent"
        >
          <Column
            @click="handleEditMode(rowData.data)"
            :style="{ width: '4%'}"
            field="id"
            header="ID"
            sortable
          >
            <template #body="rowData">
              <div @click="handleViewMode(rowData.data)" :disabled="this.blockComponent" style="cursor: pointer">
                {{ rowData.data.id }}
              </div>
            </template>
          </Column>
          <Column
            :style="{ width: '22%'}"
            field="title"
            :header="this.$langGetValue('title')"
          >
            <template #body="rowData">
              <div @click="handleViewMode(rowData.data)" :disabled="this.blockComponent" style="cursor: pointer">
                {{ rowData.data.title }}
              </div>
            </template>
          </Column>
          <Column
            :style="{ width: '10%' }"
            field="status"
            :header="this.$langGetValue('progress')"
            @click="handleViewMode(rowData.data)"
          >
            <template #body="rowData">
              <div @click="handleViewMode(rowData.data)" :disabled="this.blockComponent" :style="{cursor: 'pointer', color: rowData.data?.progress == 2 ? 'red' : 'black'}" >
                {{ this.progressItems.find((item) => item.id == rowData.data.progress)?.label }}
              </div>
            </template>
          </Column>
          <Column
            :style="{ width: '9%'}"
            field="expectedCompletionDate"
            :header="this.$langGetValue('date')"
            sortable
          >
            <template #body="rowData">
              <div @click="handleViewMode(rowData.data)" :disabled="this.blockComponent" style="cursor: pointer">
                {{ this.formatDate(rowData.data.actualCompletionDate ? rowData.data.actualCompletionDate : rowData.data.expectedCompletionDate) }}
              </div>
            </template>
          </Column>
          <Column
            :style="{ width: '17%'}"
            field="hierarchy"
            :header="this.$langGetValue('area')"
            sortable
          >
            <template #body="rowData">
              <div @click="handleViewMode(rowData.data)" :disabled="this.blockComponent" style="cursor: pointer">
                {{ this.getLabelAreas(rowData.data.hierarchy)?.join(", ") }}
              </div>
            </template>
          </Column>
          <Column
            :style="{ width: '8%'}"
            field="type"
            :header="this.$langGetValue('type')"
            sortable
          >
            <template #body="rowData">
              <div @click="handleViewMode(rowData.data)" :disabled="this.blockComponent" style="cursor: pointer">
                {{ this.typeItems.find((item) => item.id == rowData.data.type)?.label }}
              </div>
            </template>
          </Column>
          <Column :style="{ width: '10%' }" field="açao" :header="this.$langGetValue('action')">
            <template #body="rowData">
              <div style="display: flex; gap: 8px">
                <button
                  class="secondaryButton"
                  v-if="!this.blockComponent"
                  @click="handleEditMode(rowData.data)"
                >
                  <i class="pi pi-pencil"></i>
                </button>
                <button
                  @click="deleteActionPlanById(rowData.data, true)"
                  v-if="!this.blockComponent"
                  class="negativeButton"
                >
                  <i class="pi pi-trash"></i>
                </button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <Dialog 
      modal
      v-model:visible="openedForm"
      :onHide="handleClosedDialog"
      style="width: 100%; max-width: 1300px;"
    >
      <template #header>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            width: 100%;
          "
        >
          <div v-if="createAp" class="h3">{{this.$langGetValue('newActionPlan')}}</div>
          <div v-if="editingAp" class="h3">{{this.$langGetValue('editActionPlan')}}</div>
          <div v-if="viewAp" class="h3">{{this.$langGetValue('actionplan')}}</div>
          <button
            v-if="viewAp"
            style="
              height: 24px;
              width: 24px;
              background-color: transparent;
              border-width: 0px;
              cursor: pointer;
            "
            @click="enableEditing()"
          >
            <i class="pi pi-pencil"></i>
          </button>
        </div>
      </template>
      <div style="display: flex; flex-direction: row; gap: 8px; width: 100%">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            min-width: fit-content;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 32px;
              width: 100%;
            "
          >
            <div style="display: flex; flex-direction: column; width: 10%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">ID</div>
              </div>
              <InputText
                disabled
                class="inputText"
                type="text"
                v-model="this.idValue"
              />
            </div>
            <div style="display: flex; flex-direction: column; width: 60%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">{{this.$langGetValue('title')}}</div>
                <i
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <InputText
                :disabled="this.blockComponent"
                class="inputText"
                type="text"
                v-model="this.titleValue"
                :class="{
                  'invalid-input': buttonClicked && this.titleToFill(),
                }"
              />
            </div>
            <div style="display: flex; flex-direction: column; width: 15%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">{{this.$langGetValue('apstatus')}}</div>
                <i
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <Dropdown
                :disabled="this.blockComponent"
                :class="{
                  'invalid-input': this.statusValue === null && buttonClicked,
                }"
                optionLabel="label"
                @change="updateProgressAP()"
                :options="statusItems"
                v-model="this.statusValue"
              />
            </div>
            <div style="display: flex; flex-direction: column; width: 15%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">{{this.$langGetValue('progress')}}</div>
                <i
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <Dropdown
                class="inputright custom-dropdown-status"
                :disabled="true"
                v-model="progressValue"
                :options="progressItems"
                optionLabel="label"
                placeholder="Select"
                ref="progressLabel"
              />
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 32px;
              width: 100%;
            "
          >
            <div style="display: flex; flex-direction: column; width: 100%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">
                  {{this.$langGetValue('apdescription')}}
                </div>
                <i
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
                {{this.countChar(this.detailsValue) + "/50"}}
              </div>
              <Textarea
                :disabled="this.blockComponent"
                :class="{
                  'invalid-input': buttonClicked && this.descriptionToFill(),
                }"
                style="margin-top: 8px"
                v-model="this.detailsValue"
                rows="5"
                cols="30"
              />
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: start;
              justify-content: center;
              gap: 32px;
              width: 100%;
            "
          >
            <div style="display: flex; flex-direction: column; width: 25%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">
                  {{this.$langGetValue('apestimateddate')}}
                </div>
                <i v-if="statusValue?.id != 1 && statusValue?.id != 4"
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <Calendar
                :disabled="this.blockComponent"
                class="calendarPredictedConclusion"
                v-model="this.predictedConclusionValue"
                :onChange="updateProgressAP()"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/mm/yy"
                :manualInput="true"
              />
            </div>
            <div style="display: flex; flex-direction: column; width: 25%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">{{this.$langGetValue('apeactualdate')}}</div>
                <i v-if="statusValue?.id == 3"
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <Calendar
                :disabled="statusValue?.id != 3"
                class="calendarRealConclusion"
                v-model="this.realConclusionValue"
                :onChange="updateProgressAP()"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/mm/yy"
                :manualInput="true"
                :maxDate="new Date()"
              />
            </div>
            <div style="display: flex; flex-direction: column; width: 25%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">{{this.$langGetValue('type')}}</div>
                <i
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <Dropdown
                :disabled="this.blockComponent"
                :class="{
                  'invalid-input': this.typeValue === null && buttonClicked,
                }"
                optionLabel="label"
                :options="typeItems"
                v-model="this.typeValue"
              />
            </div>
            <div style="display: flex; flex-direction: column; width: 25%">
                <div
                  style="
                    display: flex;
                    justify-content: start;
                    flex-direction: row;
                    align-items: start;
                    gap: 4px;
                    margin-bottom: 4px;
                  "
                >
                <div class="body2" style="font-weight: 700">
                    <i style="color: brown; cursor: help" :title="this.$langGetValue('fieldRequired')">*</i>
                    {{this.$langGetValue('hasidpro3')}}  
                </div>
              </div>
                <div class="flex flex-row flex-wrap inputleft">
                    <div v-for="option in binaryOptions" :key="option.id" class="flex align-items-center"
                        style="padding: 2px">
                        <RadioButton v-model="hasIdPro3value" :inputId="option.id" :name="option.id" :disabled="this.blockComponent"
                            :value="option.id" ref="hasIdPro3"
                            class="hasIdPro3value" />
                        <label :for="option.id" class="ml-2">{{ this.$langGetValue(option.name) }}</label>
                    </div>
                </div>
                <div v-if="hasIdPro3value">
                    <div class="labelSubitem">
                        {{this.$langGetValue('idPRO3integration')}}  
                    </div>
                    <Chips
                      :disabled="this.blockComponent"
                      class="numberPRO3ID"
                      id="numberPRO3_ID"
                      v-model="this.pro3IDValue"
                      style="width: 100%"
                      removable
                      @keyup="updateIDPRO3ChipValue()"
                      @blur="addChipIDPRO3OnBlur"
                    />
                </div>
            </div>
          </div>


          <div style="
              gap: 32px;
              width: 100%;
            ">
            <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div
                  v-if="!viewAp"
                  class="body2"
                  style="font-weight: 700;"
                >
                  Follow Up
                </div>
                <i
                  v-if="!viewAp && idValue"
                  style="
                    color: var(--color_feedback_negative);
                    cursor: help;
                  "
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <!--<div v-if="!viewAp" style="margin-bottom: 8px">
                <span
                  class="body3"
                  style="color: var(--color_gray_medium); margin-top: 8px"
                  >O seu Follow Up deve ter no mínimo de 20 caracteres</span
                >
              </div>-->
              <div
                v-if="!viewAp"
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: end;
                  justify-content: space-between;
                "
              >
                <InputText
                  :class="{
                    'invalid-input':
                      this.followUpValue == null &&
                      this.fupRequired &&
                      buttonClicked,
                  }"
                  :disabled="this.blockComponent"
                  class="inputText"
                  type="text"
                  v-model="this.followUpValue"
                  style="width: 95%"
                />
                <div
                  class="body2"
                  style="display: flex; width: 2%; justify-content: end"
                >
                  {{ this.countChar(this.followUpValue) + "/20" }}
                </div>
              </div>
          </div>


          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 32px;
              width: 100%;
            "
          >
            <div style="display: flex; flex-direction: column; width: 100%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">
                  {{this.$langGetValue('apresponsibletodev')}}
                </div>
                <i
                  v-if="linkWith == 'issue'"
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i
                >
              </div>
              <AutoComplete class="chipsResponsible" ref="fupResponsible" multiple
              :suggestions="filteredUsers" @complete="searchUsers($event)" :disabled="this.blockComponent"
              style="width: 100%; height: 48px; margin-top: 8px"
              v-model="this.responsibleValue" />
            </div>
          </div>

          <div class="flex flex-column">
            <div class="flex flex-column flex-wrap" style="width: 100%">
              <div
                style="
                  display: flex;
                  justify-content: start;
                  flex-direction: row;
                  align-items: start;
                  gap: 4px;
                  margin-bottom: 4px;
                "
              >
                <div class="body2" style="font-weight: 700">
                  {{this.$langGetValue('area')}}
                </div>
                <i
                  v-if="linkWith == 'issue'"
                  style="color: var(--color_feedback_negative); cursor: help"
                  :title="this.$langGetValue('fieldRequired')"
                  >*</i>
              </div>

              <HierarchyComponent
                v-if="!viewAp"
                :class="{
                  'invalid-input':
                    this.areaHierarchyValue === null && buttonClicked,
                }"
                :selectedAreas="areaHierarchyValue"
                :mode="blockComponent ? 'view' : 'edit'"
                selection="multiple"
                type="originated"
                size="300px"
                @returned-areas="setObuAP"
              ></HierarchyComponent>

              <div
                style="display: flex; flex-wrap: wrap; gap: 8px; margin-top: 8px"
                v-if="areaHierarchyValue != null && viewAp"
              >
                <Chip
                  class="primaryChip"
                  style="padding: 8px 16px"
                  v-for="label in areaChips"
                  :key="label"
                >
                  {{ label }}
                </Chip>
              </div>
            </div>
            <div style="height: 30px; width: 100%"></div>
            <div class="flex flex-column flex-wrap" style="width: 50%;">
              <div
                class="flex flex-column"
                style="display: flex; flex-direction: column"
              >
                <div style="display: flex; align-items: start; gap: 4px">
                  <div class="h4" style="margin-bottom: 4px">
                    Uploads
                  </div>
                </div>
                <div style="margin-bottom: 16px">
                  <span class="body3" style="color: var(--color_gray_medium); width: 100%">{{
                    this.$langGetValue('apupload') }}
                  </span>
                </div>
                <AttachmentComponent :attachments="this.attachmentValue" :attachmentsMode="blockComponent == false ? 'edit' : 'readonly'" @returned-ids="updateAttachmentsAP"></AttachmentComponent>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
            "
          >
            <div style="display: flex; flex-direction: column; width: 100%">

              <Accordion style="margin-top: 24px">
                <AccordionTab v-if="this.idValue != null">
                  <template #header>
                    <div class="body2" style="font-weight: 700">
                      {{this.$langGetValue('fuphistory')}}
                    </div>
                  </template>
                  <DataTable
                    :value="followUpArray"
                    paginator
                    :rows="5"
                    tableStyle="min-width: 100%"
                  >
                    <Column field="date" style="width: 15%" :header="this.$langGetValue('date')">
                      <template #body="rowData">
                        {{ this.formatDate(rowData.data.date) }}
                      </template>
                    </Column>
                    <Column  style="width: 15%" :header="this.$langGetValue('user')">
                      <template #body="rowData">
                          {{ emailToName(rowData.data.user) }}
                        </template>
                    </Column>
                    <Column
                      field="text"
                      :header="this.$langGetValue('description')"
                    >
                      <template #body="rowData">
                        <Textarea v-model="rowData.data.text"
                                  disabled
                                  style="border: 0; background-color: white; color: black; width: 100%; height: 100px; font-weight: bold; resize: none; overflow-y:auto">
                        </Textarea>
                      </template>
                    </Column>
                  </DataTable>
                  <template #footer>
                  </template> 
                </AccordionTab>
                <AccordionTab v-if="this.idValue != null">
                  <template #header>
                    <div class="body2" style="font-weight: 700">
                      {{this.$langGetValue('aphistory')}}
                    </div>
                  </template>
                  <div>
                    <LogsComponent
                      :logObject="this.logsValue"
                      :fieldTreatment="fieldMapAP"
                      :logName="this.$langGetValue('actionplan')"
                      :migrated="this.integrationFromValue?.mode == 'migrate' ? true : false"
                    >
                    </LogsComponent>
                    <br /><br />
                  </div>
                </AccordionTab>
                <AccordionTab v-if="this.extraFieldsValue != null">
                  <template #header>
                    <div class="body2" style="font-weight: 700">
                      {{this.$langGetValue('extraFields')}}
                    </div>
                  </template>
                  <div>
                    <ExtraFieldsComponent :keyValue="this.extraFieldsValue"></ExtraFieldsComponent>
                    <br /><br />
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </div>
        <!-- <Divider layout="vertical" />
          <div style="display: flex; flex-direction: column; gap: 24px; width: 25%;">
            Atualizações
          </div> -->
      </div>
      <template #footer>
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: center;
              gap: 8px;
              margin-top: 10px
            "
          >
          
            <button
              :disabled="blockComponent"
              v-if="!viewAp && createAp && !this.blockComponent"
              class="primaryButton"
              style="
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: start;
              "
              @click="createNewAP"
            >
              <div class="body2" style="font-weight: 600; color: white">
                {{this.$langGetValue('buttonnewActionPlan')}}
              </div>
            </button>
            <button
              :disabled="blockComponent"
              v-if="!viewAp && editingAp && !this.blockComponent"
              class="primaryButton"
              style="
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 8px;
              "
              @click="updateExistingAP"
            >
              <div
                v-if="editingAp && !this.blockComponent"
                class="body2"
                style="font-weight: 600; color: white"
              >
              {{this.$langGetValue('buttonsaveActionPlan')}}
              </div>
            </button>
          </div>
        </template>
    </Dialog>

    <Dialog
      modal
      :draggable="false"
      :onHide="handleClosedDialogLink"
      :dismissableMask="true"
      v-model:visible="openedLink"
      style="
        justify-content: center;
        width: 60%;
        min-width: 400px;
        max-width: 700px;
      "
    >
      <template #header>
        <div class="h3" style="margin-bottom: 16px">
          {{this.$langGetValue('linkOldActionPlan')}}
        </div>
      </template>
      <div class="body2" style="font-weight: 700; margin-bottom: 4px">
        {{this.$langGetValue('searchOptions')}}
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          width: 100%;
        "
      >
        <Dropdown
          :options="searchItems"
          optionLabel="label"
          v-model="this.searchValue"
          style="width: 20%"
        />
        <InputNumber
          v-if="searchValue.value == 'ID'"
          :useGrouping="false"
          v-model="idSearchValue"
          inputId="integeronly"
          style="width: 80%"
          @keyup.enter="handleSearch"
        />
        <Chips
          v-if="searchValue.value != 'ID'"
          v-model="this.generalSearchValue"
          style="width: 100%"
          @keyup.enter="handleSearch"
        />
        <!-- <AutoComplete style="height: 48px" v-model="idSearchValue" optionLabel="id" chipLabel="id" :suggestions="filteredIdSearchList" @complete="search" field="idAndTitle" forceSelection  multiple >
            <template #chip="slotProps">
                    <div>{{ slotProps.value.id }}</div>
            </template>
        </AutoComplete> -->
        <button class="primaryButton" :disabled="loadingIconLocal" @click="handleSearch"
            :title="this.$langGetValue('menuButtonSearch')" severity="secondary"
            style="
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 8px;
            ">
            <i :class="{
            'pi pi-search': loadingIconLocal == false,
            'pi pi-spin pi-spinner': loadingIconLocal == true,
            }" style="font-weight: 900"></i>
        </button>
      </div>
      <div
        v-if="searchResult != null"
        style="display: flex; flex-direction: column; margin-top: 32px"
      >
        <DataTable
          paginator
          :rows="5"
          sortField="id"
          :sortOrder="1"
          :value="queryLink"
          columnResizeMode="fit"
          stripedRows
          tableStyle="width: 100%"
        >
          <Column
            :style="{ width: '10%', cursor: 'pointer' }"
            field="id"
            header="ID"
            sortable
          >
            <template #body="rowData">
              <div>
                {{ rowData.data.id }}
              </div>
            </template>
          </Column>
          <Column
            :style="{ width: '80%', cursor: 'pointer' }"
            field="title"
            :header="this.$langGetValue('title')"
          >
            <template #body="rowData">
              <div>
                {{ rowData.data.title }}
              </div>
            </template>
          </Column>
          <Column :style="{ width: '10%', cursor: 'pointer' }" :header="this.$langGetValue('action')">
            <template #body="rowData">
              <button
                  class="secondaryButton"
                  @click="handleEditMode(rowData.data)"
                >
                  <i class="pi pi-link"></i>
                </button>
            </template>
          </Column>
        </DataTable>
      </div>
      <template #footer>
        </template> 
    </Dialog>
    <Dialog
      :header="this.$langGetValue('attention')"
      v-model:visible="displayModalAPSaved"
      style="width: 30vw"
      :modal="true"
    >
      <p style="margin-left: 20px; margin-bottom: 10px">
        <strong> {{this.$langGetValue('attentionActionPlan')}} {{ this.$langGetValue(this.linkWith) }}.</strong>
      </p>
      <template #footer>
        <Button
          label="OK"
          @click="displayModalAPSaved = false; newAP();"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import HierarchyComponent from "../components/HierarchyComponent.vue";
import IncidentService from "../service/IncidentService.js";
import Util from "../service/Util.js";
import UserService from "../service/UserService";
import ActionPlanService from "../service/ActionPlanService"
import LogsComponent from "../components/LogsComponent";
import LaraService from "../service/LaraService";
import AttachmentComponent from "../components/AttachmentComponent.vue"
import FieldService from "../service/FieldService";
import ExtraFieldsComponent from '../components/ExtraFieldsComponent.vue'


export default {
  components: {
    HierarchyComponent,
    LogsComponent,
    AttachmentComponent,
    ExtraFieldsComponent,
  },
  props: {
    blockComponent: Boolean,
    query: Array,
    linkWith: String,
  },
  data() {
    return {
      loadingIconLocal: false,
      searchResult: null,

      user: UserService.user,
      editingAp: false,
      createAp: false,
      viewAp: false,
      fupRequired: false,

      searchValue: {value: "ID", label: "ID"},
      searchItems:   [
        {value: "ID", label: "ID"},
        {value: "Title", label: this.$langGetValue('title') }
      ],
      generalSearchValue: null,
      idSearchValue: null,

      typeItems: [],
      binaryOptions: [
          { id: 1, label: "Sim", name: "yes" },
          { id: 0, label: "Não", name: "no" },
      ],

      idValue: null,
      titleValue: null,
      statusValue: null,
      progressValue: null,
      progressLabel: null,
      detailsValue: null,
      predictedConclusionValue: null,
      realConclusionValue: null,
      typeValue: null,
      pro3IDValue: [],
      hasIdPro3value: null,
      followUpValue: null,
      responsibleValue: [],
      logsValue: [],
      areaHierarchyValue: null,
      registeredByValue: null,
      integrationFromValue: null,
      attachmentValue: [],
      extraFieldsValue: null,
      displayModalAPSaved: false,


      statusItems: [
      ],
      progressItems: [
      ],


      openedForm: false,
      openedLink: false,

      actionsPlansQuery: this.query,

      buttonClicked: false,
      isFilled: false,
      areaHierarchy: [],
      hierarchyArray: [],
      followUpArray: [],
      oldAP: null,
      areaChips: [],
      fupCountChar: null,
      filteredUsers: [],
      requiredFields: [],
      fieldMapAP: {
          "title": {
              "label": this.$langGetValue("title"),
              "isDate": false,
              "showChanges": true
          },
          "status": {
              "label": this.$langGetValue("apstatus"),
              "isDate": false,
              "showChanges": true
          },
          "actionPlanStatus": {
              "label": this.$langGetValue("apstatus"),
              "isDate": false,
              "showChanges": true
          },
          "actionPlanProgress": {
              "label": this.$langGetValue("approgress"),
              "isDate": false,
              "showChanges": true
          },
          "finalDate": {
              "label": this.$langGetValue("finalDate"),
              "isDate": false,
              "showChanges": true
          },
          "description": {
              "label": this.$langGetValue("description"),
              "isDate": false,
              "showChanges": false
          },
          "hierarchy": {
              "label": this.$langGetValue("hierarchy"),
              "isDate": false,
              "showChanges": true
          },
          "expectedCompletionDate" :{
              "label": this.$langGetValue("apfinaldate"),
              "isDate": true,
              "showChanges": true
          },
          "actualCompletionDate" :{
              "label": this.$langGetValue("apeactualdate"),
              "isDate": true,
              "showChanges": true
          },
          "type" :{
              "label": this.$langGetValue("type"),
              "isDate": false,
              "showChanges": true
          },
          "actionPlanType" :{
              "label": this.$langGetValue("type"),
              "isDate": false,
              "showChanges": true
          },
          "owner" :{
              "label": this.$langGetValue("apresponsibletodev"),
              "isDate": false,
              "showChanges": true
          },
          "pro3Id" :{
              "label": this.$langGetValue("idPRO3integration"),
              "isDate": false,
              "showChanges": true
          },
          "fup": {
              "label": this.$langGetValue("followup"),
              "isDate": false,
              "showChanges": true
          },
          "updatedTimestamp": {
              "label": this.$langGetValue("updatedTimestamp"),
              "isDate": false,
              "showChanges": false
          },
          "updatedBy": {
              "label": this.$langGetValue("updatedBy"),
              "isDate": false,
              "showChanges": false
          },
          "submittedTimestamp": {
              "label": this.$langGetValue("submittedTimestamp"),
              "isDate": false,
              "showChanges": false
          },
          "submittedBy": {
              "label": this.$langGetValue("submittedBy"),
              "isDate": false,
              "showChanges": false
          },
          "attachment": {
              "label": "Upload",
              "isDate": false,
              "showChanges": true
          },
          "registeredBy": {
              "label": this.$langGetValue("submittedBy"),
              "isDate": false,
              "showChanges": false
          },
      },

      queryLink: [
      ],
    };
  },
  computed: {
    apLabels() {
      return [
        { label: this.$langGetValue('titleminap'), value: this.titleValue, mincaracters: 5 },
        { label: this.$langGetValue('apstatus'), value: this.statusValue, mincaracters: null },
        { label: this.$langGetValue('descriptionAPMinCaract'), value: this.detailsValue, mincaracters: 50 },
        { label: this.$langGetValue('type'), value: this.typeValue, mincaracters: null },
        { label: this.$langGetValue('hasidpro3'), value: this.hasIdPro3value, mincaracters: null },
        { label: this.$langGetValue('apresponsibletodev'), value: this.responsibleValue, mincaracters: null },
        { label: this.$langGetValue('area'), value: this.areaHierarchyValue, mincaracters: null },
      ];
    },
  },
  watch: {
    searchValue(){
      this.generalSearchValue = null
      this.idSearchValue = null
    },
    actionsPlansQuery(newValue) {
      this.actionsPlansQuery = newValue;
    },
    pro3IDValue(newValue) {
      if (newValue === null && this.buttonClicked === true) {
        this.updateBorderColorInvalid(".numberPRO3ID .p-chips-multiple-container");
      } else {
        this.updateBorderColorValid(".numberPRO3ID .p-chips-multiple-container");
      }
    },
    hasIdPro3value(newValue) {
      if (newValue === null && this.buttonClicked === true) {
        this.updateBorderColorInvalid(".p-radiobutton-box");
      } else {
        this.updateBorderColorValid(".p-radiobutton-box");
      }
    },
    predictedConclusionValue(newValue) {
      if (newValue === null && this.buttonClicked === true) {
        this.updateBorderColorInvalid(
          ".calendarPredictedConclusion .p-inputtext"
        );
      } else {
        this.updateBorderColorValid(
          ".calendarPredictedConclusion .p-inputtext"
        );
      }
    },
    responsibleValue(newValue) {
      if (newValue === null && this.buttonClicked === true && this.requiredFields.includes('fupresponsible')) {
        this.updateBorderColorInvalid(
          ".chipsResponsible .p-autocomplete-multiple-container"
        );
      } else {
        this.updateBorderColorValid(
          ".chipsResponsible .p-autocomplete-multiple-container"
        );
      }
    },
    areaHierarchyValue(newValue) {
      this.areaChips = this.getLabelAreas(newValue);
      if (newValue === null && this.buttonClicked === true && this.requiredFields.includes('hierarchy')) {
        this.updateBorderColorInvalid(".p-tree");
      } else {
        this.updateBorderColorValid(".p-tree");
      }
    },
  },
  created() {
    this.areaHierarchy = IncidentService.incidentFields.hierarchy.data;
    this.populateHierarchyArray();
    this.getIDandTitle();
  },
  mounted() {
    this.statusItems = FieldService.data['action-plan'].actionPlanStatus
    this.statusItems = this.statusItems.filter((el) => el.id > 0)
    this.typeItems = FieldService.data['action-plan'].actionPlanType
    this.progressItems = FieldService.data['action-plan'].actionPlanProgress
    this.updateRequiredFields();
  },
  methods: {
    handleClosedDialogLink(){
      this.searchResult = null
      this.searchValue = {value: "ID", label: "ID"}
      this.generalSearchValue = null
      this.idSearchValue = null
    },
    handleSearch() {

      this.loadingIconLocal = true

      if (this.searchValue.value == "ID") {

        if (this.idSearchValue == null || this.idSearchValue == "") {
          console.log("Entrou no alerta do id vazio")
          this.$toast.add({
                severity: "error",
                summary: this.$langGetValue('toast5'),
                detail: this.$langGetValue('toast5Sub'),
                life: 10000,
          });
          this.loadingIconLocal = false;
        } else {
          this.searchAPbyID();
        }

      } else {

        if (this.generalSearchValue == null || this.generalSearchValue == "") {
          console.log("Entrou no alerta do title vazio")
          this.$toast.add({
                severity: "error",
                summary: this.$langGetValue('toast5'),
                detail: this.$langGetValue('toast5Sub'),
                life: 10000,
          });
          this.loadingIconLocal = false;
        } else {
          this.searchAPbyTitle();
        }

      }
    },
    searchAPbyID() {

      ActionPlanService.getAP(this.idSearchValue).then((resp) => {
          if (resp.success == true) {
              this.handleEditMode(resp.data)
              this.loadingIconLocal = false;      
              this.searchResult = 1;
              
          }
          else {
              this.$toast.add({
                  severity: "error",
                  summary: this.$langGetValue('toast8'),
                  detail: this.$langGetValue('toast8SubAP'),
                  life: 10000,
              });
              this.loadingIconLocal = false;
              return
          }
          
      })
    },

    searchAPbyTitle() {

      var payloadTitle = {
          sqlWhereClause: {
          condition: "AND",
          rules: [],
          },
          page: 1,
          pageSize: 100
      };

      payloadTitle.sqlWhereClause.rules.push({
        field: "title",
        type: "string",
        input: "text",
        operator: "contains",
        value: this.generalSearchValue[0],
      });

      ActionPlanService.apList(payloadTitle).then((resp) => {
        if (resp.success == true) {
            this.queryLink = resp.data.records
            this.loadingIconLocal = false;      
            this.searchResult = 1;
            
        }
        else {
            this.$toast.add({
                severity: "error",
                summary: this.$langGetValue('toast8'),
                detail: this.$langGetValue('toast8SubAP'),
                life: 10000,
            });
            this.loadingIconLocal = false;
            return
        }
        
    })
    },
    handleClosedDialog() {
      this.resetData();
      this.fupRequired = false;
      this.buttonClicked = false;
    },
    updateRequiredFields(){
      this.requiredFields = []
      const requiredFieldsMap = {
        'issue': ["title", "description", "status", "type", "fupresponsible", "hierarchy"],
        'incident': ["title", "description", "status", "type"]
      };
      if (requiredFieldsMap[this.linkWith]) {
        this.requiredFields = requiredFieldsMap[this.linkWith];
      }      
    },
    getStatusLabel(id) {
      return this.statusItems.find((item) => item.id == id)?.label;
    },
    getIDandTitle() {
      const queryArray = [
        { id: "100", title: "Título exemplo" },
        { id: "101", title: "Título exemplo" },
        { id: "102", title: "Título exemplo" },
        { id: "200", title: "Título exemplo" },
        { id: "201", title: "Título exemplo" },
      ];
      this.idSearchList = queryArray.map((item) => ({
        ...item,
        idAndTitle: `${item.id} - ${item.title}`,
      }));
    },
    searchUsers(event) {
        let tempArray = [];

        LaraService.searchUser(event.query).then((response) => {
            response.forEach((user) => {
                if (user.attributes.mail != "") {
                    tempArray.push(user.attributes.mail.toLowerCase());
                }
            });
            this.filteredUsers = tempArray;
            console.log(this.filteredUsers)
        });
    },
    updateAttachmentsAP(value) {
        this.attachmentValue = value
    },
    updateIDPRO3ChipValue() {
      if (this.pro3IDValue) {
        let newArray = this.pro3IDValue.filter(function (item) {
            return !isNaN(item); // Remove o elemento que não é número
        });
        // converting to int
        this.pro3IDValue = newArray.map(function(str) {
          return parseInt(str, 10);
        });
      }
    },
    updateProgressAP() {
        let status = this.statusValue?.id
        let expDate = this.predictedConclusionValue
        let progress

        if (status == null) {
          progress = 1
        }

        if ((status == 1 || status == 2) & expDate < new Date().setUTCHours(0, 0, 0, 0)) {
            progress = 2
            this.realConclusionValue = null
        }
        if ((status == 1 || status == 2) & expDate >= new Date().setUTCHours(0, 0, 0, 0)) {
            progress = 1
            this.realConclusionValue = null
        }

        if ((status == 1 || status == 2) & expDate == null) {
            progress = 1
            this.realConclusionValue = null
        }

        if (status == 3) {
            progress = 3
        }

        if (status == 4) {
            progress = 4
        }

        this.progressValue = this.progressItems.find((item) => item.id == progress)

      },
    search(event) {
      setTimeout(() => {
        if (!event.query.trim()?.length) {
          this.filteredIdSearchList = this.idSearchList;
        } else {
          this.filteredIdSearchList = this.idSearchList.filter((item) => {
            return item.id.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    countChar(string) {
      if (string != null) {
        this.fupCountChar = string?.length;
        return string?.length;
      } else {
        this.fupCountChar = 0;
        return 0;
      }
    },
    emailToName(email) {
      return Util.emailToName(email);
    },
    updateBorderColorInvalid(selector) {
      var elements = document.querySelectorAll(selector);
      elements.forEach(function (element) {
        element.style.borderColor = "#EB3D47";
      });
    },

    updateBorderColorValid(selector) {
      var elements = document.querySelectorAll(selector);
      elements.forEach(function (element) {
        element.style.borderColor = "";
      });
    },

    populateHierarchyArray() {
      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(" -> "),
        };

        const result = [info];

        if (tree.children && tree.children?.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      for (let i = 0; i < this.areaHierarchy?.length; i++) {
        this.hierarchyArray = this.hierarchyArray.concat(
          visitNodes(this.areaHierarchy[i])
        );
      }
    },

    setObuAP(value) {
      this.areaHierarchyValue = value;
    },

    handleNewAP() {
      this.createAp = true;
      this.editingAp = false;
      this.viewAp = false;

      this.openedForm = true;
      this.openedLink = false;

      this.fupRequired = false;

      this.resetData();
    },

    handleViewMode(data) {
      this.editingAp = false;
      this.createAp = false;
      this.viewAp = true;

      this.idValue = data.id;
      this.updateValuesByData(data)
      

      this.openedForm = true;
      this.openedLink = false;

      // POR ENQUANTO NAO TERÁ MODO DE VISUALIZAÇÃO MESMO, SÓ EDIÇÃO
      this.enableEditing()
    },

    enableEditing() {
      this.editingAp = true;
      this.viewAp = false;
      this.createAp = false;

      if (this.idValue != null) {
        this.fupRequired = true;
      }
    },

    handleEditMode(data) {

      this.updateValuesByData(data)
      
      this.enableEditing();

      this.openedForm = true;
      this.openedLink = false;
    },

    createNewAP() {
      if (this.checkIfIsFilled()) {
        this.displayModalAPSaved = true;
      }
    },

    updateValuesByData(data) {

      this.idValue = data.id;
      this.titleValue = data.title;
      this.statusValue = this.statusItems.find((item) => item.id == data.status);
      this.progressValue = this.progressItems.find((item) => item.id == data.progress)
      this.detailsValue = data.description;
      this.predictedConclusionValue = data.expectedCompletionDate ? new Date(data.expectedCompletionDate) : null;
      this.realConclusionValue = this.statusValue.id == 3 ? new Date(data.actualCompletionDate) : null;
      this.typeValue = this.typeItems.find((item) => item.id == data.type);
      this.pro3IDValue = data.pro3Id ? data.pro3Id : [];
      this.hasIdPro3value = data.pro3Id?.length > 0 ? 1 : 0;
      this.followUpValue = null;
      this.responsibleValue = data.owner;
      this.areaHierarchyValue = data.hierarchy;
      this.followUpArray = data.fup;
      this.registeredByValue = data.registeredBy,
      this.attachmentValue = data.attachment ? data.attachment : [],
      this.oldAP = data;
      this.logsValue = data.logs
      this.integrationFromValue = data.integrationFrom
      this.extraFieldsValue = data.extraFields
      
    },

    handleLink(data){

      this.updateValuesByData(data);
      this.enableEditing();

      this.openedForm = true;
      this.openedLink = false;

    },

    newAP() {
      const newAP = {
        id: this.idValue,
        title: this.titleValue,
        status: this.statusValue.id,
        progress: this.progressValue.id,
        description: this.detailsValue,
        expectedCompletionDate: this.predictedConclusionValue,
        actualCompletionDate: this.realConclusionValue,
        hierarchy: this.areaHierarchyValue,
        type: this.typeValue.id,
        owner: this.responsibleValue,
        pro3Id: this.pro3IDValue,
        fup: this.updateFollowUp(this.followUpValue),
        logs: this.logsValue,
        registeredBy: this.user,
        attachment: this.attachmentValue,
        integrationFrom: this.integrationFromValue,
        extraFields: this.extraFieldsValue
      };

      this.actionsPlansQuery.push(newAP);

      this.$emit("update-ap-query", this.actionsPlansQuery);

      this.openedForm = false;
      this.editingAp = false;
      this.createAp = false;
      this.openedLink = false;

      this.resetData();
    },

    updateFollowUp(lastFup) {
      if (this.followUpValue != null) {
        const today = new Date();

        this.followUpArray.push({
          date: today,
          user: this.user,
          text: lastFup,
        });
      }
      return this.followUpArray;
    },

    updateExistingAP() {
      if (this.checkIfIsFilled()) {
        this.deleteActionPlanById(this.oldAP, false);
        this.displayModalAPSaved = true;
        this.fupRequired = false;
      }
    },

    deleteActionPlanById(apData, emit) {
      this.actionsPlansQuery = this.actionsPlansQuery.filter(
        (actionPlan) => actionPlan !== apData
      );
      if (emit) {
        console.log("emitindo")
        this.$emit("update-ap-query", this.actionsPlansQuery);
      }
    },

    addChipIDPRO3OnBlur() {
      const inputElement = document.getElementById('numberPRO3_ID');
      // Simular pressionar Enter no input
      const enterEvent = new KeyboardEvent('keydown', {
        key: 'Enter',
        keyCode: 13,
        code: 'Enter',
        which: 13,
        bubbles: true,
      });
      inputElement.dispatchEvent(enterEvent);
      this.updateIDPRO3ChipValue();
      
    },

    checkIfIsFilled() {
      this.buttonClicked = true;

      if (
        (this.titleValue != null && this.requiredFields.includes('title')) &&
        (this.statusValue != null && this.requiredFields.includes('status')) &&
        (this.detailsValue != null && this.requiredFields.includes('description')) &&
        (this.statusValue?.id != 1 && this.statusValue?.id != 4 && this.predictedConclusionValue != null) &&
        (this.areaHierarchyValue != null && this.requiredFields.includes('hierarchy')) &&
        (this.typeValue != null && this.requiredFields.includes('type')) &&
        this.hasIdPro3value != null &&
        (this.statusValue?.id == 3 && this.realConclusionValue != null) &&
        (this.responsibleValue?.length > 0 && this.requiredFields.includes('fupresponsible')) &&
        this.progressValue != null
      ) {
        if (
          (this.fupRequired == true &&
            this.followUpValue != null &&
            this.fupCountChar > 19) ||
          this.fupRequired == false
        ) {
          this.isFilled = true;
          this.buttonClicked = false;
          return true;
        }
      }

      var notFilledInputs = null;

      // pintando

      if ((this.titleValue == null || this.titleValue?.length < 5) && this.requiredFields.includes('title')) {
        this.updateBorderColorInvalid(
          ".title .p-inputtext"
        );
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('titleminap');
        } else {
          notFilledInputs = this.$langGetValue('titleminap');
        }
      } else {
        this.updateBorderColorValid(
          ".title .p-inputtext"
        );
      }

      if (this.statusValue == null && this.requiredFields.includes('status')) {
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('apstatus');
        } else {
          notFilledInputs = this.$langGetValue('apstatus');
        }
      }

      if ((this.detailsValue == null || this.detailsValue?.length < 50) && this.requiredFields.includes('description')) {
        this.updateBorderColorInvalid(
          ".details .p-inputtext"
        );
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('descriptionAPMinCaract');
        } else {
          notFilledInputs = this.$langGetValue('descriptionAPMinCaract');
        }
      } else {
        this.updateBorderColorValid(
          ".details .p-inputtext"
        );
      }

      if (this.typeValue == null && this.requiredFields.includes('type')) {
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('type');
        } else {
          notFilledInputs = this.$langGetValue('type');
        }
      }

      if (this.hasIdPro3value == null) {
        this.updateBorderColorInvalid(
          ".p-radiobutton-box"
        );
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('hasidpro3');
        } else {
          notFilledInputs = this.$langGetValue('hasidpro3');
        }
      } else {
        this.updateBorderColorValid(
          ".p-radiobutton-box"
        );
      }

      if (this.predictedConclusionValue == null && this.statusValue?.id != 1 && this.statusValue?.id != 4) {
        this.updateBorderColorInvalid(
          ".calendarPredictedConclusion .p-inputtext"
        );
      } else {
        this.updateBorderColorValid(
          ".calendarPredictedConclusion .p-inputtext"
        );
      }

      if (this.responsibleValue?.length == 0 && this.requiredFields.includes('fupresponsible')) {
        this.updateBorderColorInvalid(
        ".chipsResponsible .p-autocomplete-multiple-container"
        );
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('apresponsibletodev');
        } else {
          notFilledInputs = this.$langGetValue('apresponsibletodev');
        }
      } else {
        this.updateBorderColorValid(
        ".chipsResponsible .p-autocomplete-multiple-container"
        );
      }


      if (this.areaHierarchyValue == null && this.requiredFields.includes('hierarchy')) {
        this.updateBorderColorInvalid(".p-tree");
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('hierarchy');
        } else {
          notFilledInputs = this.$langGetValue('hierarchy');
        }
      } else {
        this.updateBorderColorValid(
          ".p-tree"
        );
      }

      // casos especiais: fup, data conclusao real e id pro3
      if (this.fupRequired == true && (this.followUpValue == null || this.fupCountChar < 20)) {
        if (notFilledInputs != null) {
          notFilledInputs = notFilledInputs + ", " + this.$langGetValue('followupMinCaract');
        } else {
          notFilledInputs = this.$langGetValue('followupMinCaract');
        }
      }

      if (this.statusValue?.id == 3 && this.realConclusionValue == null) {
          this.updateBorderColorInvalid(
            ".calendarRealConclusion .p-inputtext"
          );
          if (notFilledInputs != null) {
            notFilledInputs = notFilledInputs + ", " + this.$langGetValue('apeactualdate');
          } else {
            notFilledInputs =  this.$langGetValue('apeactualdate');
          }
      } else {
        this.updateBorderColorValid(
            ".calendarRealConclusion .p-inputtext"
          );
      }

      if (this.statusValue?.id != 1 && this.statusValue?.id != 4 && this.predictedConclusionValue == null) {
          this.updateBorderColorInvalid(
            ".calendarPredictedConclusion .p-inputtext"
          );
          if (notFilledInputs != null) {
            notFilledInputs = notFilledInputs + ", " + this.$langGetValue('apestimateddate');
          } else {
            notFilledInputs =  this.$langGetValue('apestimateddate');
          }
      } else {
        this.updateBorderColorValid(
            ".calendarPredictedConclusion .p-inputtext"
          );
      }

      if (this.hasIdPro3value == 1 && this.pro3IDValue?.length == 0) {
        console.log("###########entrou no if do hasIdPro3value == 1")
          this.updateBorderColorInvalid(
            ".numberPRO3ID .p-chips-multiple-container"
          );
          if (notFilledInputs != null) {
            notFilledInputs = notFilledInputs + ", " + this.$langGetValue('idPRO3integration');
          } else {
            notFilledInputs =  this.$langGetValue('idPRO3integration');
          }
      } else {
        this.updateBorderColorValid(
            ".numberPRO3ID .p-chips-multiple-container"
          );
      }

      if (notFilledInputs?.length > 0) {
        this.$toast.add({
          severity: "error",
          summary:
            this.$langGetValue('toast7Sub') + " " +
            notFilledInputs +
            ".",
          life: 3000,
        });

        return false;
      } else {
        return true;
      }
    

    },
    titleToFill() {
      return this.titleValue == null || this.titleValue == "" ? true : false
    },
    descriptionToFill() {
      return this.detailsValue == null || this.detailsValue?.length < 50 ? true : false
    },
    getLabelAreas(hierarchy) {
      if (hierarchy != null) {
        const labelAreas = [];

        hierarchy.forEach((tempArea) => {
          labelAreas.push(
            this.hierarchyArray.find((object) => object.id === tempArea.id)
              ?.label
          );
        });
        return labelAreas;
      }
    },

    resetData() {
      this.idValue = null;
      this.titleValue = null;
      this.statusValue = null;
      this.progressValue = this.progressItems.find((item) => item.id == 1);
      this.detailsValue = null;
      this.predictedConclusionValue = null;
      this.realConclusionValue = null;
      this.typeValue = null;
      this.pro3IDValue = [];
      this.hasIdPro3value = null;
      this.attachmentValue = [];
      this.extraFieldsValue = null;
      this.logsValue = [];
      this.integrationFromValue = null;
      this.followUpValue = null;
      this.responsibleValue = [];
      this.areaHierarchyValue = null;
      this.buttonClicked = false;
      this.oldAP = null;
    },

    formatDate(inputDate) {
      if (inputDate) {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      } else {
        return ''
      }
    },

    arrayToString(arr) {
      let string = null;

      for (let i = 0; i < arr?.length; i++) {
        if (i == 0) {
          string = arr[i];
        } else {
          string = string + ", " + arr[i];
        }
      }

      return string;
    },

    handleLinkAP() {
      this.openedLink = !this.openedLink;
      this.openedForm = false;
    },
  },
};
</script>

<style>
.invalid-input {
  border-color: var(--color_feedback_negative);
}

.invalid-input-text {
  color: var(--color_feedback_negative);
}

.layout-sidebar {
  box-shadow: none !important;
  border-radius: 0px;
  background-color: var(--color_gray_xlight);
  height: 100vh;
  padding: 24px 0px 88px 40px;
  position: fixed;
  left: 0px;
  top: 71px;
  z-index: 999;
}

/* width */
.layout-sidebar::-webkit-scrollbar {
  width: 3px;
}

.layout-sidebar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
.layout-sidebar::-webkit-scrollbar-thumb {
  background: var(--color_brand_medium);
  border-radius: 100px;
}
</style>
